import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavbarHome from "../Components/NavbarHome";
import FloatingIcons from "../Components/FloatingIcons";
import Footer from "../Components/Footer";
import { withRouter } from "react-router-dom";
import { api_url } from "Variables";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
/* //!---------------------------------- Completed ---------------------------------- */

function CaseDetails() {
	const { url } = useParams();
	const [cases, setCases] = useState([]);

	useEffect(() => {
		allCases();
	}, []);

	const allCases = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + `case-studies?where[url]=${url}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setCases(result);
				console.log("Cases", cases);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div>
			<NavbarHome className="navbar-home sticky" />
			<FloatingIcons />
			<div id="butter">
				<main>
					<section class="about-us inner-banner-case inner-hero">
						<div class="container">
							<div class="twocol-row">
								<div class="col-36">
									<div class=" inner-hero-heading hero-main">
										<div className="side-heading">
											<h6 class=" hero-main-subheading left-element">
												Case Study
											</h6>
										</div>
										{cases
											? cases.length > 0
												? cases.map((n, index) => (
														<div className="side-heading">
															<h1
																class="hero-main-heading fw-700"
																id="hero-heading"
															>
																{n.title}
															</h1>
														</div>
												  ))
												: ""
											: ""}
									</div>
								</div>
								<div class="col-30">
									{cases
										? cases.length > 0
											? cases.map((n, index) => (
													<div>
														<p className="fw-600">{n["case-study-page-tag"]}</p>
														<span
															dangerouslySetInnerHTML={{
																__html: n["case-study-page-short-description"],
															}}
														></span>
													</div>
											  ))
											: ""
										: ""}
								</div>
							</div>
						</div>
					</section>

					<section className="container challenges">
						{cases
							? cases.length > 0
								? cases.map((n, index) => (
										<div key={index}>
											<h1 className="heading">Challenges</h1>
											<span
												className="para"
												dangerouslySetInnerHTML={{
													__html: n["challenges-text"],
												}}
											></span>
											<div className="img-wrapper">
												<img
													src={
														n["challenges-image"] && n["challenges-image"].thumb
													}
													width="100%"
													height="auto"
												/>
											</div>
										</div>
								  ))
								: ""
							: ""}
					</section>

					<section className="container process">
						{cases
							? cases.length > 0
								? cases.map((n, index) => (
										<div key={index}>
											<h1 className="process-heading">Design Process</h1>
											<span className="process-text">
												{n["design-process-text"]}
											</span>
											<Swiper
												loop={false}
												slidesPerView={5}
												breakpoints={{
													300: {
														slidesPerView: 2,
														spaceBetween: 5,
													},
													577: {
														slidesPerView: 3,
														spaceBetween: 15,
													},
													768: {
														slidesPerView: 4,
														spaceBetween: 30,
													},
													1200: {
														slidesPerView: 5,
														spaceBetween: 40,
													},
												}}
											>
												<div class="research-design__slider swiper">
													<div class="swiper-wrapper mt-3">
														<SwiperSlide>
															<div class="swiper-slide">
																<div class="generic--head research-design__slider--border">
																	<h5 class="generic--head__subhead fw-700 black-col">
																		{" "}
																		{n["design-process-1"]}
																	</h5>
																	<span class="generic--head research-design__slider--slide-number">
																		01
																	</span>
																</div>
															</div>
														</SwiperSlide>
														<SwiperSlide>
															<div class="swiper-slide">
																<div class="generic--head research-design__slider--border">
																	<h5 class="generic--head__subhead fw-700 black-col">
																		{" "}
																		{n["design-process-2"]}
																	</h5>
																	<span class="generic--head research-design__slider--slide-number">
																		02
																	</span>
																</div>
															</div>
														</SwiperSlide>
														<SwiperSlide>
															<div class="swiper-slide">
																<div class="generic--head research-design__slider--border">
																	<h5 class="generic--head__subhead fw-700 black-col">
																		{" "}
																		{n["design-process-3"]}
																	</h5>
																	<span class="generic--head research-design__slider--slide-number">
																		03
																	</span>
																</div>
															</div>
														</SwiperSlide>
														<SwiperSlide>
															<div class="swiper-slide">
																<div class="generic--head research-design__slider--border">
																	<h5 class="generic--head__subhead fw-700 black-col">
																		{" "}
																		{n["design-process-4"]}
																	</h5>
																	<span class="generic--head research-design__slider--slide-number">
																		04
																	</span>
																</div>
															</div>
														</SwiperSlide>
														<SwiperSlide>
															<div class="swiper-slide">
																<div class="generic--head research-design__slider--border">
																	<h5 class="generic--head__subhead fw-700 black-col">
																		{" "}
																		{n["design-process-5"]}
																	</h5>
																	<span class="generic--head research-design__slider--slide-number">
																		05
																	</span>
																</div>
															</div>
														</SwiperSlide>
													</div>
												</div>
											</Swiper>
										</div>
								  ))
								: ""
							: ""}
					</section>

					<section className="container solution">
						<div className="row">
							<div className="col-md-4">
								{cases
									? cases.length > 0
										? cases.map((n, index) => (
												<h1
													dangerouslySetInnerHTML={{
														__html: n["proposed-solution-title"],
													}}
												></h1>
										  ))
										: ""
									: ""}
							</div>
							<div className="offset-md-1 col-md-7">
								{cases
									? cases.length > 0
										? cases.map((n, index) => (
												<div key={index}>
													<p
														dangerouslySetInnerHTML={{
															__html: n["proposed-solution-description"],
														}}
													></p>
													<button className="arrow-anchor black-col ">
														<span className="solution-url">
															{n["proposed-solution-url"]}
														</span>
														<div className="img-box">
															<svg
																version="1.1"
																id="Layer_1"
																xmlns="http://www.w3.org/2000/svg"
																x="0px"
																y="0px"
																viewBox="0 0 19 16"
																style={{ enableBackground: "new 0 0 19 16" }}
															>
																<path
																	className="st0"
																	d="M16.2,7.2l0,1.8L1.1,9.3l0-1.8L16.2,7.2z"
																/>
																<path
																	className="st0"
																	d="M11,13.1l-1.1-1.4L14.7,8l-5-3.6l1-1.5l6.8,5.2L11,13.1z"
																/>
															</svg>
														</div>
													</button>
												</div>
										  ))
										: ""
									: ""}
							</div>
						</div>
					</section>

					<section class="our-brands">
						{cases
							? cases.length > 0
								? cases.map((n, index) => (
										<div class="twocol-row">
											<div class="col-46">
												<div class="img-wrapper">
													<img
														src={n["image-1"] && n["image-1"].thumb}
														alt="Left Img"
													/>
												</div>
											</div>
											<div class="col-50 our-brands__right-img">
												<div class="img-wrapper">
													<img
														src={n["image-2"] && n["image-2"].thumb}
														alt="Right Img"
													/>
												</div>
											</div>
										</div>
								  ))
								: ""
							: ""}
					</section>

					<section class="container about-casestudy ">
						{cases
							? cases.length > 0
								? cases.map((n, index) => (
										<div class="twocol-row my-5">
											<div class="col-50 left-col">
												<div class="img-wrapper">
													<img src={n["image-3"] && n["image-3"].thumb} alt />
												</div>
											</div>
											<div class="col-50 right-col">
												<div class="img-wrapper">
													<img src={n["image-4"] && n["image-4"].thumb} alt />
												</div>
												<div class="generic--head">
													<p
														class="generic--head__sec-para fw-600 black-col "
														dangerouslySetInnerHTML={{
															__html: n["bottom-description"],
														}}
													></p>
												</div>
											</div>
										</div>
								  ))
								: ""
							: ""}
					</section>

					<section class="grid-layout section">
						<div class="container">
							{cases
								? cases.length > 0
									? cases.map((n, index) => (
											<div class="twocol-row">
												<div class="col-60 col-left">
													<div class="img-wrapper leftimg img-hover-zoom">
														<img src={n["image-5"] && n["image-5"].thumb} alt />
													</div>
													<div class="img-wrapper midimg img-hover-zoom">
														<img src={n["image-6"] && n["image-6"].thumb} alt />
													</div>
												</div>
												<div class="col-40 col-right">
													<div class="img-wrapper rightimg img-hover-zoom">
														<img src={n["image-7"] && n["image-7"].thumb} alt />
													</div>
												</div>
											</div>
									  ))
									: ""
								: ""}
						</div>
					</section>
				</main>
				<Footer />
			</div>
		</div>
	);
}

export default withRouter(CaseDetails);
