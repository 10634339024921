import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { api_url } from "Variables";
import { gsap } from "gsap";
import img from "../../Assets/logo3.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/*! //! -------------------------------- Completed ------------------------------- */

function OurWork() {
	const [allWork, setAllWork] = useState([]);
	const headingRef = useRef(null);

	useEffect(() => {
		getAllWork();
	}, []);

	useLayoutEffect(() => {
		ScrollTrigger.refresh();
		const workHeading = document.getElementById("workHeading");
		if (workHeading) {
			gsap.to(workHeading, {
				x: 150,
				duration: 0.55,
				scrollTrigger: {
					trigger: workHeading,
					start: "-200px center",
					end: "450px center",
					scrub: 1,
				},
			});
		}
	});

	const getAllWork = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "case-studies/", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setAllWork(result);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<>
			<section
				className="ourWork section translateX-parent Work-slider"
				data-delay="0s"
				id="ourWork"
			>
				<div className="line-heading translateX-right">
					<h3 className="outline-text" id="workHeading" ref={headingRef}>
						Our Work{" "}
					</h3>
				</div>
				<div className="swiper-container">
					<Swiper
						slidesPerView={5}
						speed={2000}
						spaceBetween={40}
						centeredSlides={true}
						direction={"horizontal"}
						className="services-slider swiper mySwiper "
						initialSlide={1}
						breakpoints={{
							300: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							576: {
								slidesPerView: 2,
								spaceBetween: 70,
							},
							700: {
								slidesPerView: 2.5,
								spaceBetween: 70,
							},
							992: {
								slidesPerView: 3,
								spaceBetween: 10,
							},
							1200: {
								slidesPerView: 5,
								spaceBetween: 40,
							},
						}}
					>
						{allWork
							? allWork.length > 0
								? allWork.map((n, index) => (
										<div key={index}>
											<SwiperSlide>
												<div
													className="Work-slider-wrap height-transition opacity-transition"
													data-delay="0"
												>
													<div className="Work-slider-img drag--img ">
														<img
															src={
																n["homepage-image"] && n["homepage-image"].thumb
															}
															alt="our-work"
															className="mangnifer-image"
															width="369"
															height="404"
														/>
													</div>
													<div className="Work-slider-logo my-4">
														<img
															src={n.logo.full_url}
															alt=""
															style={{
																width: "215px",
																height: "100px",
																objectFit: "fill",
															}}
														/>
													</div>
													<p className="Work-slider-desc">
														{n["homepage-description"]}
													</p>
													<div className="dg-a-wrap">
														<a
															href={`/case-studies/${n["url"]}`}
															className="arrow-anchor"
														>
															<div className="img-box">
																<svg
																	version="1.1"
																	id="Layer_1"
																	xmlns="http://www.w3.org/2000/svg"
																	x="0px"
																	y="0px"
																	viewBox="0 0 19 16"
																>
																	<path
																		className="st0"
																		d="M16.2,7.2l0,1.8L1.1,9.3l0-1.8L16.2,7.2z"
																	/>
																	<path
																		className="st0"
																		d="M11,13.1l-1.1-1.4L14.7,8l-5-3.6l1-1.5l6.8,5.2L11,13.1z"
																	/>
																</svg>
															</div>
														</a>
													</div>
												</div>
											</SwiperSlide>
										</div>
								  ))
								: ""
							: ""}
					</Swiper>
				</div>
			</section>
		</>
	);
}

export default OurWork;
