import React, { useState, useEffect, useRef } from "react";
import NavbarHome from "../Components/NavbarHome";
import FloatingIcons from "../Components/FloatingIcons";
import Footer from "../Components/Footer";
import { Row, Col, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { api_url } from "Variables";

/* //!---------------------------------- Completed ---------------------------------- */

function CaseStudies() {
	const [pages, setPages] = useState([]);
	const [categories, setCategories] = useState([]);
	const [all, setAll] = useState(true);
	const [showtabs, setTabs] = useState(true);
	const [activeCategory, setActiveCategory] = useState(null);
	const [cases, setCases] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(9);
	const [nextDisabled, setNextDisabled] = useState(true);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [numDisabled, setNumDisabled] = useState(false);
	const [lastItems, setLastItems] = useState([]);
	const [startItems, setStartItems] = useState([]);
	const [pageNo, setPageNum] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const offSet = useRef(0);

	useEffect(() => {
		Pages();
		Categories();
		allCatCases();
		allCases();
	}, []);

	const Pages = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "pages/?where[id]=59", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setPages([...result]);
				console.log("pages", pages);
			})
			.catch((error) => console.log("error", error));
	};

	const Categories = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "case-study-categories", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setCategories([...result]);
				console.log("Categories", categories);
			})
			.catch((error) => console.log("error", error));
	};

	const handleCategoryClick = (index) => {
		setActiveCategory(index);
	};

	const caseStudies = async (id) => {
		const caseId = id;
		console.log("ID", id);
		console.log("Index", activeCategory);
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + `case-studies?where[category]=${caseId}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setCases(result);
				setNextDisabled(true);
				setPrevDisabled(true);
				setNumDisabled(true);
				console.log("All", cases);
			})
			.catch((error) => console.log("error", error));
	};

	const allCases = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + `case-studies`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setLastItems(result.slice(-1));
				setStartItems(result.slice(0, 1));
				setTotalPages(Math.ceil(result.length / itemsPerPage));
				if (result.length >= itemsPerPage) {
					setNextDisabled(false);
				}

				setNumDisabled(false);
				console.log("Cases", cases);
			})
			.catch((error) => console.log("error", error));
	};

	const allCatCases = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			api_url + `case-studies?limit=${itemsPerPage}&offset=${offSet.current}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setCases(result);
				console.log("Cases", cases);
			})
			.catch((error) => console.log("error", error));
	};

	const NextAllCat = async () => {
		console.log("Pages", itemsPerPage);
		console.log("Offset", offSet.current);
		console.log(
			"api",
			`case-studies?limit=${itemsPerPage}&offset=${offSet.current}`
		);

		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		console.log("Last", lastItems);
		fetch(
			api_url + `case-studies?limit=${itemsPerPage}&offset=${offSet.current}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				const resultIds = result.map((item) => item.id);
				const lastItemsIds = lastItems.map((item) => item.id);
				console.log("cases", cases);

				const anyIdMatches = resultIds.some((resultId) =>
					lastItemsIds.includes(resultId)
				);

				if (anyIdMatches) {
					setNextDisabled(true);
					setCases(result);
					setPageNum((prevPages) => prevPages + 1);
				} else {
					setNextDisabled(false);
					setCases(result);
					setPageNum((prevPages) => prevPages + 1);
				}

				console.log("cases", cases);
			})
			.catch((error) => console.log("error", error));
	};

	const PrevAllCat = async () => {
		console.log("Start", startItems);
		console.log("Pages", totalPages);
		console.log("Offset", offSet.current);

		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			api_url + `case-studies?limit=${itemsPerPage}&offset=${offSet.current}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				const resultIds = result.map((item) => item.id);
				const startItemsIds = startItems.map((item) => item.id);
				const anyIdMatches = resultIds.some((resultId) =>
					startItemsIds.includes(resultId)
				);

				if (anyIdMatches) {
					setPrevDisabled(true);
					setNextDisabled(false);

					setCases(result);

					setPageNum((prevPages) => prevPages - 1);
				} else {
					setNextDisabled(false);

					setCases(result);
					setPageNum((prevPages) => prevPages - 1);
				}

				console.log("cases", cases);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div>
			<NavbarHome />
			<FloatingIcons />
			<div id="butter">
				<main>
					<section class="about-us inner-banner inner-hero">
						<div class="container">
							<div class="twocol-row">
								<div class="col-36">
									<div class=" inner-hero-heading hero-main">
										{pages
											? pages.length > 0
												? pages.map((n, index) => (
														<div>
															<h6 class="hero-main-subheading left-element">
																{n["side-title"]}
															</h6>
															<h1 class="hero-main-heading fw-700">
																{n["page-main-title"]}
															</h1>
														</div>
												  ))
												: ""
											: ""}
									</div>
								</div>
								<div class="col-30">
									{pages
										? pages.length > 0
											? pages.map((n, index) => (
													<span
														dangerouslySetInnerHTML={{
															__html: n["content"],
														}}
													></span>
											  ))
											: ""
										: ""}
								</div>
							</div>
						</div>
					</section>

					<section className="container">
						<Row>
							<ul className="case-tabs">
								<li
									className={all ? "active-tab web-active" : "web-active"}
									onClick={() => {
										allCases();
										setAll(true);
										setTabs(false);
									}}
								>
									All
								</li>

								{categories
									? categories.length > 0
										? categories.map((n, index) => (
												<li
													key={index}
													className={`btn ${
														showtabs && index === activeCategory
															? "active-tab web-active"
															: "web-active"
													}`}
													onClick={() => {
														handleCategoryClick(index);
														caseStudies(n.id);
														setAll(false);
														setTabs(true);
													}}
												>
													{n.title}
												</li>
										  ))
										: ""
									: ""}
							</ul>
						</Row>

						<div className="all">
							<div class="right-heading mt-5" id="aboutHeading">
								<div class="line-heading">
									{pages
										? pages.length > 0
											? pages.map((n, index) => (
													<h3 class="outline-text primary-color">
														{n["random-title"]}
													</h3>
											  ))
											: ""
										: ""}
								</div>
							</div>
							<Row className="case-cards-row mb-5">
								{cases
									? cases.length > 0
										? cases.map((n, index) => (
												<Col md={6} lg={6} xs={12} className="case-cards-col">
													<a href={`/case-studies/${n["url"]}`}>
														<Card>
															<div class="img-hover-zoom">
																<Card.Img
																	variant="top"
																	src={
																		n["homepage-image"]
																			? n["homepage-image"]["thumb"]
																			: ""
																	}
																/>
															</div>

															<Card.Body>
																<div class="hero-main">
																	<h5 class="hero-main-subheading black-col fw-600">
																		{n.title}
																	</h5>
																	<p class="hero-main-section-txt s-font black-col fw-500 mb-0">
																		{n["case-study-page-tag"]}
																	</p>
																</div>
																<div class="anchor-wrapper">
																	<button class="arrow-anchor black-col">
																		<span>View Case study</span>
																		<div class="img-box">
																			<svg
																				version="1.1"
																				id="Layer_1"
																				xmlns="http://www.w3.org/2000/svg"
																				x="0px"
																				y="0px"
																				viewBox="0 0 19 16"
																				style={{
																					enableBackground: "new 0 0 19 16",
																				}}
																			>
																				<path
																					className="st0"
																					d="M16.2,7.2l0,1.8L1.1,9.3l0-1.8L16.2,7.2z"
																				/>
																				<path
																					className="st0"
																					d="M11,13.1l-1.1-1.4L14.7,8l-5-3.6l1-1.5l6.8,5.2L11,13.1z"
																				/>
																			</svg>
																		</div>
																	</button>
																</div>
															</Card.Body>
														</Card>
													</a>
												</Col>
										  ))
										: ""
									: ""}
							</Row>
							<Row
								style={{
									justifyContent: "center",
									margin: "10px auto",
									display: "flex",
									flexDirection: "row",
									flexWrap: "noWrap",
								}}
							>
								<button
									className="pagination-btn"
									onClick={() => {
										offSet.current = offSet.current - itemsPerPage;

										setNextDisabled(false);
										PrevAllCat();
									}}
									disabled={prevDisabled}
								>
									Previous
								</button>
								<p
									style={{
										width: "100px",
										margin: "10px 10px",
										color: "black",
										textAlign: "center",
										visibility: numDisabled ? "hidden" : "visible",
									}}
								>
									{pageNo} of {totalPages}
								</p>
								<button
									className="pagination-btn"
									onClick={() => {
										offSet.current = offSet.current + itemsPerPage;

										setPrevDisabled(false);
										NextAllCat();
									}}
									disabled={nextDisabled}
								>
									Next
								</button>
							</Row>
						</div>
					</section>
				</main>
				<Footer />
			</div>
		</div>
	);
}

export default withRouter(CaseStudies);
