import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import plus from "../../Assets/Images/plus.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/*! //! -------------------------------- Completed ------------------------------- */

function FAQ() {
	const [faqs, setFaq] = useState([]);
	const headingRef = useRef(null);

	useEffect(() => {
		getAllFAQs();
	}, []);

	useLayoutEffect(() => {
		ScrollTrigger.refresh();
		const faqHeading = document.getElementById("faqHeading");
		if (faqHeading) {
			gsap.to(faqHeading, {
				x: 150,
				duration: 0.55,
				scrollTrigger: {
					trigger: faqHeading,
					start: "-300px center",
					end: "450px center",
					scrub: 1,
				},
			});
		}
	});

	const getAllFAQs = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "home-page-faqs/", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setFaq(result);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<>
			<section className="faq section">
				<div className="line-heading">
					<h3 className="outline-text" id="faqHeading" ref={headingRef}>
						Faq
					</h3>
				</div>
				<div className="faq-box">
					<Swiper
						slidesPerView={5}
						speed={2000}
						spaceBetween={30}
						centeredSlides={true}
						direction={"horizontal"}
						initialSlide={1}
						className="faq-slider swiper drag-img-white"
						breakpoints={{
							300: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							600: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							992: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							1200: {
								slidesPerView: 5,
								spaceBetween: 40,
							},
						}}
					>
						{faqs
							? faqs.length > 0
								? faqs.map((faq, index) => (
										<div
											className="swiper-slide"
											data-aos="fade-up"
											data-aos-duration="1500"
											key={index}
										>
											<SwiperSlide>
												<div class="direction-aware-hover">
													<div className="swiper-slide">
														<div className="faq-slider-wrap ">
															<div className="faq-slider-desc-wrap">
																<h2 className="faq-slide-no">{index + 1} </h2>
																<h4 className="faq-slide-haeding">
																	{" "}
																	{faq["question"].length > 80
																		? faq["question"].substring(0, 80) + "..."
																		: faq["question"]}{" "}
																</h4>
																<div className="faq-slider-arrow-wrap">
																	<div className="faq-slider-arrow">
																		<img
																			className="lazy-img"
																			src={plus}
																			alt=""
																			width="16"
																			height="16"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="direction-aware-hover__left"></div>
													<div class="direction-aware-hover__right"></div>
													<div class="direction-aware-hover__top"></div>
													<div class="direction-aware-hover__bottom"></div>
													<div class="direction-aware-hover__content">
														<div className="faq-slider-desc">
															<h2>{index + 1} </h2>
															<div
																className="faq-slider-d"
																dangerouslySetInnerHTML={{
																	__html: faq.answer,
																}}
															/>
														</div>
													</div>
												</div>
											</SwiperSlide>
										</div>
								  ))
								: ""
							: ""}
					</Swiper>
				</div>
			</section>
		</>
	);
}

export default FAQ;
