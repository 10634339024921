import React, { useEffect, useRef, useState } from "react";
import img from "../../Assets/Images/culture4.webp";
import { Col, Row } from "react-bootstrap";
import { api_url } from "Variables";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

function Industries() {
	const [ind, setInd] = useState([]);
	const [activeSlide, setActiveSlide] = useState(0);
	const [activeIndex, setActiveIndex] = useState(0);

	const [defaultInd, setDefaultInd] = useState();
	const swiper = useRef(null);

	useEffect(() => {
		Industries();
	}, []);

	const Industries = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "industries-we-serve/", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setInd(result);
			})
			.catch((error) => console.log("error", error));
	};

	useEffect(() => {
		if (ind.length > 0) {
			setDefaultInd(ind[0]);
		}
		if (swiper.current) {
			const activeIndex = swiper.current.activeIndex;
			setActiveSlide(isNaN(activeIndex) ? 0 : activeIndex);
		}
	}, [ind, activeSlide]);
	const handleNext = () => {
		if (swiper.current) {
			swiper.current.slideNext();
			setActiveSlide(swiper.current.realIndex);
		}
	};

	const handlePrev = () => {
		if (swiper.current) {
			swiper.current.slidePrev();
			setActiveSlide(swiper.current.realIndex);
		}
	};

	return (
		<section class="serviceDevelopment">
			<div style={{ marginLeft: "20px" }}>
				<h1
					style={{ color: "#033780", fontSize: "34px" }}
					class=" generic--head__head fw-600 gray-col capitalize letter-spacing"
				>
					Industries We Serve
				</h1>
				<h3
					style={{ marginBottom: "26px", fontSize: "20px" }}
					class=" generic--head__head fw-600 gray-col capitalize letter-spacing"
				>
					Discover Our Impact Across Varied Business Sectors{" "}
				</h3>
			</div>
			<div className="industries-swiper">
				<Row>
					<Col md={8}>
						<div className="swiper-left">
							<Swiper
								loop={false}
								slidesPerView={3}
								spaceBetween={20}
								centeredSlides={true}
								speed={2000}
								modules={[Navigation]}
								className="mySwiper swiper my-industries-swiper"
								onInit={(swiperInstance) => {
									swiper.current = swiperInstance;
									console.log("Swiper initialized:", swiper.current);
								}}
								breakpoints={{
									300: {
										slidesPerView: 1,
										spaceBetween: 5,
									},
									600: {
										slidesPerView: 2,
										spaceBetween: 15,
									},
									992: {
										slidesPerView: 3,
										spaceBetween: 30,
									},
									1200: {
										slidesPerView: 3,
										spaceBetween: 20,
									},
								}}
								onSwiper={(swiperInstance) => (swiper.current = swiperInstance)}
								onSlideChange={(swiper) => {
									setActiveSlide(swiper.activeIndex);
								}}
							>
								{ind
									? ind.length > 0
										? ind.map((n, index) => (
												<div key={index}>
													<SwiperSlide>
														<div className="industries-slide">
															{/* */}
															<div className="image-ind">
																{n.image && n.image.thumb ? (
																	<img src={n.image.thumb} />
																) : (
																	""
																)}
																<h1 className="">
																	{index + 1 <= 9
																		? `0${index + 1}`
																		: `${index + 1}`}
																</h1>{" "}
															</div>
															<h3 className="text-black">{n.title}</h3>
															<hr />
														</div>{" "}
													</SwiperSlide>
												</div>
										  ))
										: ""
									: ""}
							</Swiper>
						</div>
					</Col>

					<Col md={4}>
						<div className="swiper-right">
							{ind
								? ind.length > 0
									? ind.map((n, index) =>
											activeSlide === index ? (
												<div>
													<h2 className="text-black">{n.title}</h2>
													<p className="text-black">{n.description}</p>
												</div>
											) : (
												""
											)
									  )
									: ""
								: ""}

							{activeSlide !== ind.length - 1 ? (
								<div
									className="custom-next-button custom-nav-button"
									onClick={handleNext}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										fill="white"
										className="bi bi-arrow-right-short"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
										/>
									</svg>
								</div>
							) : null}
							{activeSlide !== 0 ? (
								<div
									className="custom-prev-button custom-nav-button"
									onClick={handlePrev}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										fill="white"
										class="bi bi-arrow-left-short"
										viewBox="0 0 16 16"
									>
										<path
											fill-rule="evenodd"
											d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
										/>
									</svg>
								</div>
							) : null}
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
}

export default Industries;
