import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import FloatingIcons from "Components/FloatingIcons";
import Footer from "Components/Footer";
import NavbarHome from "Components/NavbarHome";
import AboutExpertise from "Components/AboutExpertise";
import Blog from "Components/HomeComponents/Blog";
import Conversation from "Components/HomeComponents/Conversation";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/* //!---------------------------------- Completed ---------------------------------- */

function About() {
	const headingRef = useRef(null);
	const [content, setContent] = useState([]);

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 10,
		slidesToScroll: 2,
		initialSlide: 1,
		autoplay: true,
		speed: 5000,
		autoplaySpeed: 5000,
		cssEase: "linear",
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 577,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
		],
	};

	useEffect(() => {
		AboutUs();
	}, []);

	useLayoutEffect(() => {
		gsap.to("#aboutsHeading", {
			x: 150,
			duration: 0.55,
			scrollTrigger: {
				trigger: "#aboutsHeading",
				start: "top center",
				end: "1300px center",
				scrub: 1,
			},
		});
	}, []);

	const AboutUs = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "about-us", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setContent([...result]);
				console.log("Content", content);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div>
			<NavbarHome />
			<FloatingIcons />
			<div id="butter">
				<main>

					<section className="about-us inner-banner inner-hero">
						<div class="container">
							<div class="twocol-row">
								<div class="col-36">
									{content
										? content.length > 0
											? content.map((n, index) => (
													<div class=" inner-hero-heading hero-main">
														<h6 class="hero-main-subheading left-element">
															{n["subtitle"]}
														</h6>
														<h1 class="hero-main-heading fw-700">
															{n["title-1"]}
														</h1>
													</div>
											  ))
											: ""
										: ""}
								</div>
								<div class="col-30">
									{content
										? content.length > 0
											? content.map((n, index) => <p class>{n["excerpt"]}</p>)
											: ""
										: ""}
								</div>
							</div>
						</div>
					</section>

					<section class="about-second-hero">
						<div class="twocol-row about-second-hero-heading">
							<div class="col-50">
								<div class="img-box">
									{content
										? content.length > 0
											? content.map((n, index) => (
													<img src={n["image-1"]&&n["image-1"].thumb} alt="" />
											  ))
											: ""
										: ""}
								</div>
							</div>
							<div class="right-heading" id="aboutHeading">
								<div class="line-heading" id="aboutsHeading" ref={headingRef}>
									{content
										? content.length > 0
											? content.map((n, index) => (
													<h3 class="outline-text primary-color">
														{n["sliding-text"]}
													</h3>
											  ))
											: ""
										: ""}
								</div>
							</div>
						</div>
						<div class="twocol-row about-second-hero-center">
							<div class="col-50 about-second-hero-pilot-head">
								{content
									? content.length > 0
										? content.map((n, index) => (
												<div class="hero-main">
													<h3 class="hero-main-subheading s-font gray-col fw-700">
														{n["title-2"]}
													</h3>
													<span
														class="hero-main-section-txt gray-col s-font"
														dangerouslySetInnerHTML={{
															__html: n["section-1-title-description"],
														}}
													></span>
												</div>
										  ))
										: ""
									: ""}
							</div>
							<div class="col-50">
								{content
									? content.length > 0
										? content.map((n, index) => (
												<div class="about-second-hero-cardWrap">
													<div class="about-second-hero-cardWrap-card p-lr-4">
														<div class="hero-main">
															<span
																class="hero-main-section-txt gray-col s-font"
																dangerouslySetInnerHTML={{
																	__html: n["section-1-1-title-discription"],
																}}
															></span>
														</div>
														<div class="hero-main">
															<p class="hero-main-section-txt gray-col s-font"></p>
														</div>
													</div>
													<div class="about-second-hero-cardWrap-card gray-bg">
														<div class="img-box">
															<img
																class="lazy-img"
																src={n["section-1-1-image"]&&n["section-1-1-image"].thumb}
																alt="laptop"
															/>
														</div>
													</div>
													<div class="about-second-hero-cardWrap-card primary-bg">
														<div class="img-box">
															<img
																class="lazy-img"
																src={n["section-1-2-image"]&&n["section-1-2-image"].thumb}
																alt="mobile"
															/>
														</div>
													</div>
													<div class="about-second-hero-cardWrap-card gray-bg7 p-lr-4">
														<div class="hero-main">
															<span
																class="hero-main-section-txt gray-col s-font"
																dangerouslySetInnerHTML={{
																	__html: n["section-1-2-title-description"],
																}}
															></span>
														</div>
													</div>
												</div>
										  ))
										: ""
									: ""}
							</div>
						</div>
					</section>

					<section class="future-tech about-tech section">
						<div class="container">
							{Array.isArray(content) &&
								content.length > 0 &&
								content.map((n, index) => (
									<div
										class="hero-main col-50"
										data-aos="fade-up"
										data-aos-duration="1500"
									>
										<h1 class="hero-main-subheading fw-700 letter-spacing">
											{n["tech-stack-title"]}
										</h1>
										<p class="hero-main-section-txt s-font">
											{n["tech-stack-subtitle"]}
										</p>
									</div>
								))}
						</div>

						<div class="future-tech-logos">
							{Array.isArray(content) &&
								content.length > 0 &&
								content.map((n, index) => (
									<div>
										<Slider {...settings}>
											{Array.isArray(n["tech-stack"]) &&
												n["tech-stack"].length > 0 &&
												n["tech-stack"].map((n, index) => (
													<div className="logo-bg">
														<img class src={n.thumb} alt="" />
													</div>
												))}
										</Slider>{" "}
										<br />
										<br />
										<Slider {...settings}>
											{Array.isArray(n["tech-stack"]) &&
												n["tech-stack"].length > 0 &&
												n["tech-stack"].map((n, index) => (
													<div className="logo-bg">
														<img class src={n.thumb} alt="" />
													</div>
												))}
										</Slider>{" "}
									</div>
								))}
						</div>
					</section>

					<AboutExpertise />
					<Blog />
					<Conversation />
					
				</main>
				<Footer />
			</div>
		</div>
	);
}

export default About;
