import React, { useEffect, useState } from "react";
import whatsapp from "../Assets/Images/social/whatsapp.svg";
import email from "../Assets/Images/social/email.svg";
import phone from "../Assets/Images/social/phone.svg";
import { api_url } from "../Variables";

/*! //! -------------------------------- Completed ------------------------------- */

function FloatingIcons() {
	const [links, setLinks] = useState("");

	useEffect(() => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "site-info/", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setLinks(result[0]);
			})
			.catch((error) => console.log("error", error));
	}, []);

	return (
		<div className="dg-social-wrapper dg-flotaing-icons">
			<div className="img-box whatsapp">
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`https://wa.me/${links.whatsapp}`}
				>
					<img className="svg-convert" src={whatsapp} alt="whatsapp" />
				</a>
			</div>
			<div className="img-box email">
				<a href={`mailto:${links.email}`} className="menu-open">
					<img className="svg-convert" src={email} alt="email" />
				</a>
			</div>
			<div className="img-box phone">
				<a href={`tel: ${links["contact-no"]}`}>
					<img className="svg-convert" src={phone} alt="phone" />
				</a>
			</div>
		</div>
	);
}

export default FloatingIcons;
