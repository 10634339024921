import React from "react";
import NavbarHome from "../Components/NavbarHome";
import FloatingIcons from "../Components/FloatingIcons";
import HeroSection from "../Components/HomeComponents/HeroSection";
import Services from "../Components/HomeComponents/Services";
import OurWork from "../Components/HomeComponents/OurWork";
import Brands from "../Components/HomeComponents/Brands";
import Awards from "../Components/HomeComponents/Awards";
import FAQ from "../Components/HomeComponents/FAQ";
import Footer from "../Components/Footer";
import Conversation from "../Components/HomeComponents/Conversation";
import Blog from "../Components/HomeComponents/Blog";
import { withRouter } from "react-router-dom";
import Testimonial from "Components/HomeComponents/Testimonials";
import Industries from "Components/HomeComponents/Industries";

/* //!---------------------------------- Completed ---------------------------------- */

function Home() {
	return (
		<div>
			<NavbarHome />
			<FloatingIcons />
			<div id="butter">
				<main>
					<HeroSection />
					<Services />
					<Industries />
					<OurWork />
					<Brands />
					<Awards />
					<Testimonial />
					<FAQ />
					<Blog />
					<Conversation />
				</main>
				<Footer />
			</div>
		</div>
	);
}

export default withRouter(Home);
