import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import web_dev_bg from "../../Assets/Images/services/webdevelopment-bg.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper/modules";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/*! //! -------------------------------- Completed ------------------------------- */

function Services() {
	const [service_categories, setServiceCategories] = useState([]);
	const [services, setServices] = useState([]);
	const [currentSlide, setCurrentSlide] = useState(1);
	const headingRef = useRef(null);
	const [selectedSlideId, setSelectedSlideId] = useState(6);

	useEffect(() => {
		getServices2();
	}, []);

	useEffect(() => {
		servicesCategories();
		getServices(selectedSlideId);
	}, [selectedSlideId]);

	useLayoutEffect(() => {
		ScrollTrigger.refresh();

		const servicesHeading = document.getElementById("servicesHeading");
		if (servicesHeading) {
			gsap.to(servicesHeading, {
				x: 150,
				duration: 0.55,
				scrollTrigger: {
					trigger: servicesHeading,
					start: "-300px center",
					end: "450px center",
					scrub: 1,
				},
			});
		}
	}, []);

	const servicesCategories = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		try {
			const response = await fetch(
				`${api_url}service-categories/`,
				requestOptions
			);
			const result = await response.json();
			setServiceCategories(result);
		} catch (error) {
			console.log("error", error);
		}
	};

	const getServices = async (selectedSlideId) => {
		const storedServices = JSON.parse(localStorage.getItem("services"));

		if (storedServices && storedServices[selectedSlideId]) {
			setServices(storedServices[selectedSlideId]);
		} else {
			var requestOptions = {
				method: "GET",
				redirect: "follow",
			};

			try {
				const response = await fetch(
					`${api_url}services?where[category]=${selectedSlideId}`,
					requestOptions
				);
				const result = await response.json();
				setServices(result);

				localStorage.setItem(
					"services",
					JSON.stringify({
						...storedServices,
						[selectedSlideId]: result,
					})
				);
			} catch (error) {
				console.log("error", error);
			}
		}
	};

	const getServices2 = async () => {
		try {
			const response = await fetch(`${api_url}service-categories/`);
			const result = await response.json();

			const thirdSlide = result[2];
			if (thirdSlide) {
				setSelectedSlideId(thirdSlide.id);
				getServices(thirdSlide.id);
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	return (
		<>
			<section className="services animate services-section" data-delay="0s">
				<section className="services-wrap ">
					<div className="services-items translateX-parent">
						<div className="line-heading translateX-right">
							<h3
								className="outline-text"
								id="servicesHeading"
								ref={headingRef}
							>
								Services{" "}
							</h3>
						</div>
						<div className="services-slider-wrap " id="mouse-wheel">
							<Swiper
								slidesPerView={5}
								spaceBetween={40}
								speed={800}
								centeredSlides={true}
								mousewheel={true}
								direction={"vertical"}
								className="services-slider swiper mySwiper"
								initialSlide={2}
								modules={[Mousewheel, Pagination]}
								onSlideChange={(swiper) => {
									setCurrentSlide(swiper.activeIndex);
									const selectedSlide = service_categories[swiper.activeIndex];
									setSelectedSlideId(
										selectedSlide ? selectedSlide["id"] : null
									);
								}}
							>
								{service_categories
									? service_categories.length > 0
										? service_categories.map((n, index) => (
												<div key={index}>
													<div className="services-haeding ">
														<SwiperSlide>
															<h2>
																<a href={`/services/${n["url"]}`}>
																	{n["service-name"]}
																</a>
															</h2>
														</SwiperSlide>
													</div>
												</div>
										  ))
										: ""
									: ""}
							</Swiper>
							<div className="scroll-text">
								<span className="mouse">
									<span className="move"></span>
								</span>
								<span className="head">Scroll</span>
							</div>
						</div>
						<div className="services-slider swiper">
							<div className="swiper-wrapper">
								{service_categories
									? service_categories.length > 0
										? service_categories.map((n, index) =>
												currentSlide === index ? (
													<div className="swiper-slide">
														<div className="service-item twocol-row">
															<div className="services-item-wrap twocol-row">
																<div className="services-itemImg">
																	<div className="img-box">
																		{n["home-page-image"] &&
																		n["home-page-image"].thumb ? (
																			<img
																				className="lazy-img"
																				src={n["home-page-image"].thumb}
																				alt=""
																				loading="eager"
																			/>
																		) : (
																			""
																		)}
																	</div>
																	<div className="description-box">
																		<div
																			dangerouslySetInnerHTML={{
																				__html: n["home-page-description"],
																			}}
																		/>
																	</div>
																	<div className="services-home-list">
																		{services
																			? services.length > 0
																				? services.map((n, index) => (
																						<ul className="home-lists">
																							<li>
																								<a
																									href={
																										n["category"] &&
																										n["category"].url
																											? `/services/${n["category"].url}/${n.url}`
																											: ""
																									}
																									key={index}
																								>
																									{n.title}
																								</a>
																							</li>
																						</ul>
																				  ))
																				: ""
																			: ""}
																	</div>
																</div>
																<div className="dg-a-wrap services-a">
																	<a href="/" className="arrow-anchor">
																		<span>Read More</span>
																		<div className="img-box">
																			<img
																				className="svg-convert"
																				src="/assets/svg/anchor-arrow.svg"
																				alt=""
																				loading="eager"
																			/>
																		</div>
																	</a>
																</div>
																<div className="service-bg">
																	{n["homepage-bg-image"] &&
																	n["homepage-bg-image"].thumb ? (
																		<img
																			className="lazy-img"
																			src={n["homepage-bg-image"].thumb}
																			alt=""
																			loading="eager"
																		/>
																	) : (
																		<img
																			className="lazy-img"
																			src={web_dev_bg}
																			alt="Default Image Alt Text"
																			loading="eager"
																		/>
																	)}
																</div>
															</div>
														</div>
													</div>
												) : (
													""
												)
										  )
										: ""
									: ""}
							</div>
						</div>
						<ul className="mbl-view-services-wrap">
							{service_categories
								? service_categories.length > 0
									? service_categories.map((n, index) => (
											<li className="mbl-view-service-item" key={index}>
												<a href={`/services/${n["url"]}`}>
													{n["service-name"]}
												</a>
											</li>
									  ))
									: ""
								: ""}
						</ul>
					</div>
				</section>
			</section>
		</>
	);
}

export default Services;
