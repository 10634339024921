import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import FloatingIcons from "Components/FloatingIcons";
import Footer from "Components/Footer";
import NavbarHome from "Components/NavbarHome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url } from "Variables";

/*! //! -------------------------------- Completed ------------------------------- */

function Blog() {
	const [all, setAll] = useState(true);
	const [categories, setCategories] = useState([]);
	const [showtabs, setTabs] = useState(true);
	const [activeCategory, setActiveCategory] = useState(null);
	const [setblogs, setBlogs] = useState([]);
	const [allblogs, setAllBlogs] = useState([]);
	const [pages, setPages] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(9);
	const [nextDisabled, setNextDisabled] = useState(true);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [numDisabled, setNumDisabled] = useState(false);
	const [lastItems, setLastItems] = useState([]);
	const [startItems, setStartItems] = useState([]);
	const [pageNo, setPageNum] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const offSet = useRef(0);
	const slider = useRef(null);

	useEffect(() => {
		Pages();
		Categories();
		allCatBlogs();
		allBlogs();
	}, []);

	const Pages = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "pages/?where[id]=61", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setPages([...result]);
				console.log("pages", pages);
			})
			.catch((error) => console.log("error", error));
	};

	const blog = {
		dots: false,
		arrows: true,
		autoplay: false,
		speed: 900,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,

		initialSlide: 0,
		centerMode: true,
		centerPadding: "0",
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 300,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const Categories = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "categories", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setCategories([...result]);
				console.log("Categories", categories);
			})
			.catch((error) => console.log("error", error));
	};

	const handleCategoryClick = (index) => {
		setActiveCategory(index);
	};

	const blogs = async (id) => {
		const caseId = id;
		console.log("ID", id);
		console.log("Index", activeCategory);
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + `posts?where[category]=${caseId}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setBlogs(result);
				setNextDisabled(true);
				setPrevDisabled(true);
				setNumDisabled(true);
				console.log("All", setblogs);
			})
			.catch((error) => console.log("error", error));
	};

	const allBlogs = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + `posts`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setAllBlogs(result);
				setLastItems(result.slice(-1));
				setStartItems(result.slice(0, 1));
				setTotalPages(Math.ceil(result.length / itemsPerPage));
				if (result.length >= itemsPerPage) {
					setNextDisabled(false);
				}

				setNumDisabled(false);
				console.log("Last", lastItems);
				console.log("allblogs", setblogs);
			})
			.catch((error) => console.log("error", error));
	};

	const allCatBlogs = () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(
			api_url + `posts?limit=${itemsPerPage}&offset=${offSet.current}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				setBlogs(result);
				console.log("setblogs", setblogs);
			})
			.catch((error) => console.log("error", error));
	};

	const NextAllCat = async () => {
		console.log("Pages", itemsPerPage);
		console.log("Offset", offSet.current);
		console.log("api", `posts?limit=${itemsPerPage}&offset=${offSet.current}`);

		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		console.log("Last", lastItems);
		fetch(
			api_url + `posts?limit=${itemsPerPage}&offset=${offSet.current}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				const resultIds = result.map((item) => item.id);
				const lastItemsIds = lastItems.map((item) => item.id);
				console.log("setblogs", setblogs);

				const anyIdMatches = resultIds.some((resultId) =>
					lastItemsIds.includes(resultId)
				);

				if (anyIdMatches) {
					setNextDisabled(true);
					setBlogs(result);
					setPageNum((prevPages) => prevPages + 1);
				} else {
					setNextDisabled(false);
					setBlogs(result);
					setPageNum((prevPages) => prevPages + 1);
				}

				console.log("setblogs", setblogs);
			})
			.catch((error) => console.log("error", error));
	};

	const PrevAllCat = async () => {
		console.log("Start", startItems);
		console.log("Pages", totalPages);
		console.log("Offset", offSet.current);

		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};
		fetch(
			api_url + `posts?limit=${itemsPerPage}&offset=${offSet.current}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("Result", result);
				const resultIds = result.map((item) => item.id);
				const startItemsIds = startItems.map((item) => item.id);
				const anyIdMatches = resultIds.some((resultId) =>
					startItemsIds.includes(resultId)
				);

				if (anyIdMatches) {
					setPrevDisabled(true);
					setNextDisabled(false);

					setBlogs(result);

					setPageNum((prevPages) => prevPages - 1);
				} else {
					setNextDisabled(false);

					setBlogs(result);
					setPageNum((prevPages) => prevPages - 1);
				}

				console.log("setblogs", setblogs);
			})
			.catch((error) => console.log("error", error));
	};

	function formatDateToDdMmYyyy(dateString) {
		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, "0");
		const month = months[date.getMonth()];
		const year = date.getFullYear();

		return `${day} ${month} ${year}`;
	}

	return (
		<div>
			<NavbarHome />
			<FloatingIcons />

			<div id="butter">
				<main>
					<section class="about-us inner-banner inner-hero">
						<div class="container">
							<div class="twocol-row">
								<div class="col-40">
									<div class=" inner-hero-heading hero-main">
										{pages
											? pages.length > 0
												? pages.map((n, index) => (
														<div>
															<h6 class="hero-main-subheading left-element">
																{n["side-title"]}
															</h6>
															<h1 class="hero-main-heading fw-700">
																{n["page-main-title"]}
															</h1>
														</div>
												  ))
												: ""
											: ""}
									</div>
								</div>
								<div class="col-30">
									{pages
										? pages.length > 0
											? pages.map((n, index) => (
													<span
														dangerouslySetInnerHTML={{
															__html: n["content"],
														}}
													></span>
											  ))
											: ""
										: ""}
								</div>
							</div>
						</div>
					</section>

					<section className="container ">
						<Row>
							<ul className="case-tabs">
								<li
									className={
										all
											? "active-tab web-active text-center"
											: "web-active text-center"
									}
									onClick={() => {
										allBlogs();
										setAll(true);
										setTabs(false);
									}}
								>
									All
								</li>

								{categories
									? categories.length > 0
										? categories.map((n, index) => (
												<li
													key={index}
													className={`btn ${
														showtabs && index === activeCategory
															? "active-tab web-active"
															: "web-active"
													}`}
													onClick={() => {
														handleCategoryClick(index);
														blogs(n.id);
														setAll(false);
														setTabs(true);
													}}
												>
													{n.title}
												</li>
										  ))
										: ""
									: ""}
							</ul>
						</Row>
					</section>

					<section>
						<div className="container mt-3 blog-tabs">
							<Row>
								{setblogs
									? setblogs.length > 0
										? setblogs.map((n, index) => (
												<Col md={4} xs={12}>
													<Card className="shadow blog-page-card">
														<Card.Img
															variant="top"
															src={
																n["blog-page-image"] &&
																n["blog-page-image"]["thumb"]
															}
														/>
														<Card.Body>
															<div className="d-flex justify-content-between">
																<h4 className="text-black">
																	<strong>
																		{n["category"] && n["category"]["title"]}
																	</strong>
																</h4>
																<span className="text-black">
																	{formatDateToDdMmYyyy(n["publish-date"])}
																</span>
															</div>
															<Link to={`/blog/${n["url"]}`}>
																<h5 className="text-black">{n["title"]}</h5>

																<span
																	dangerouslySetInnerHTML={{
																		__html:
																			n["short-description-top"].slice(0, 114) +
																			"...",
																	}}
																	style={{ color: "black !important" }}
																></span>
															</Link>
														</Card.Body>
													</Card>
												</Col>
										  ))
										: ""
									: ""}
							</Row>
							<Row
								style={{
									justifyContent: "center",
									margin: "10px auto",
									display: "flex",
									flexDirection: "row",
									flexWrap: "noWrap",
								}}
							>
								<button
									className="pagination-btn"
									onClick={() => {
										offSet.current = offSet.current - itemsPerPage;

										setNextDisabled(false);
										PrevAllCat();
									}}
									disabled={prevDisabled}
								>
									Previous
								</button>
								<p
									className="pagination-p"
									style={{
										width: "100px",
										margin: "10px 10px",
										color: "black",
										textAlign: "center",
										visibility: numDisabled ? "hidden" : "visible",
									}}
								>
									{pageNo} of {totalPages}
								</p>
								<button
									className="pagination-btn"
									onClick={() => {
										offSet.current = offSet.current + itemsPerPage;

										setPrevDisabled(false);
										NextAllCat();
									}}
									disabled={nextDisabled}
								>
									Next
								</button>
							</Row>
						</div>

						<div className="container mt-3 blog-tabs blogSliderSection">
							<Slider {...blog} ref={slider} className="blog-page-slider">
								{allblogs
									? allblogs.length > 0
										? allblogs.map((n, index) => (
												<div>
													<Card className="shadow blog-page-card">
														<Card.Img
															variant="top"
															src={
																n["blog-page-image"] &&
																n["blog-page-image"]["thumb"]
															}
														/>
														<Card.Body>
															<div className="d-flex justify-content-between">
																<h4 className="text-black">
																	<strong>
																		{n["category"] && n["category"]["title"]}
																	</strong>
																</h4>
																<span className="text-black">
																	{formatDateToDdMmYyyy(n["publish-date"])}
																</span>
															</div>
															<Link to={`/blog/${n["url"]}`}>
																<h5 className="text-black">{n["title"]}</h5>
																<span
																	dangerouslySetInnerHTML={{
																		__html:
																			n["short-description-top"].slice(0, 114) +
																			"...",
																	}}
																	style={{ color: "black !important" }}
																></span>
															</Link>
														</Card.Body>
													</Card>
												</div>
										  ))
										: ""
									: ""}
							</Slider>
							<button
								className="blog-slider-btn-prev"
								onClick={() => slider?.current?.slickPrev()}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 27 44"
									width="40"
									height="40"
								>
									<path
										d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z"
										fill="#033780"
									/>
								</svg>
							</button>
							<button
								className="blog-slider-btn-next"
								onClick={() => slider?.current?.slickNext()}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 27 44"
									width="40"
									height="40"
								>
									<path
										d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z"
										fill="#033780"
									/>
								</svg>
							</button>
						</div>
					</section>
				</main>
			</div>

			<Footer />
		</div>
	);
}

export default Blog;
